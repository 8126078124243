<template>
  <div>
    <v-row justify="center" class="bank-profile-container-row">
      <v-col cols="2" class="bank-profile-side-panel">
        <app-bank-and-profile-side-panel></app-bank-and-profile-side-panel>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import AppBankAndProfileSidePanel from '@/components/layout/BankAndProfileSideMenu.vue'
export default {
  name: 'profileMenu',
  components: {
    AppBankAndProfileSidePanel
  },
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {}
}
</script>

<style lang="scss">
.profile-tab-list-container {
  padding-bottom: 20px;
  padding-top: 20px;
}
.profile-tab-list-item {
  display: inline-block;
  padding: 0 10px;
  .profile-tab-list-item-button {
    background-image: linear-gradient(to left, var(--v-base_3-base), var(--v-base_3-base));
    letter-spacing: 0;
    min-width: 150px !important;
    &.active {
      background-image: linear-gradient(to left, var(--v-primary-base), var(--v-primary_3-base));
    }
  }
}

.profile-page-form {
  padding: 0px 20px 25px;
  max-width: 600px;
  margin: auto;
}

.bank-account-card {
  min-width: 350px;
  border: 2px solid var(--v-primary-base) !important;
  border-radius: 10px !important;
  &.inComplete {
    border: 2px solid var(--v-secondary_2-base) !important;
  }
}

.bank-balance {
  background-color: var(--v-base_3-base);
  height: 36px;
  .v-divider {
    height: 100%;
    display: inline-block;
    border-color: red;
    border-right: 5px solid var(--v-navigator_active-base)!important;
  }
  .balance-amount {
    min-width: 100px;
  }
}

.bank-navigation-button {
  letter-spacing: 0;
  border-radius: 0px;
  padding: 24px !important;
  &.v-btn--active {
    span {
      font-weight: bold;
      color: var(--v-navigator_active-base);
    }
  }
  span {
    display: inline-block;
  }
}

.bank-page-title {
  border-bottom: 4px solid var(--v-title_color-base);
}

.bank-transaction-stepper {
  box-shadow: none;
  min-height: 350px;
}

@media (max-width: 1500px) {
  .bank-profile-side-panel {
    min-width: 250px;
  }
}
@media (max-width: 1350px) {
}
@media (max-width: 1263px) {
  .bank-page-title {
    font-size: 16px;
  }
}
@media (max-width: 1024px) {
}
@media (max-width: 959px) {
}
@media (max-width: 599px) {
  .bank-account-card{
    min-width: 250px;
  }
}
@media (max-width: 425px) {
}
</style>
